import { AudioInfo } from "./models/audio-info";
import Hls from 'hls.js';
import { getCurrentBrowserFingerPrint } from "@rajesh896/broprint.js";

declare namespace window {
    function stopListen();
    function audioInit(url: string);
    function getAudioInfo();
    function setVolume(newVolume: number);
    function setMuted(isMuted: boolean);
}

//declare const Hls;
const hls = new Hls();
let indexClassRef;
let hlsObject: Hls;
let audioElement: HTMLAudioElement | undefined;
let visualizer;

window.audioInit = function (url) {
    // Because of Blazor SSR, we need to get the reference in the function
    audioElement = getAudioElement();

    //const canvas: HTMLCanvasElement = document.querySelector("#audioVisualizer");
    //visualizer = visualizer || new AudioVisualizer(canvas, audioElement);

    //if (Hls.isSupported()) {
    // Originally hls.js docs say it uses video element
    // var video = document.getElementById('video');

    // If you are using the ESM version of the library (hls.mjs), you
    // should specify the "workerPath" config option here if you want
    // web workers to be used. Note that bundlers (such as webpack)
    // will likely use the ESM version by default.
    hlsObject = new Hls({
        playlistLoadPolicy: {
            default: {
                maxTimeToFirstByteMs: 10000,
                maxLoadTimeMs: 20000,
                timeoutRetry: {
                    maxNumRetry: 10,
                    retryDelayMs: 50,
                    maxRetryDelayMs: 200,
                },
                errorRetry: {
                    maxNumRetry: 20,
                    retryDelayMs: 1000,
                    maxRetryDelayMs: 8000,
                },
            },
        },
    });

    // MEDIA_ATTACHED event is fired by hls object once MediaSource is ready
    hlsObject.on(Hls.Events.MEDIA_ATTACHED, function () {
        console.log('video and hls.js are now bound together !');
    });
    hlsObject.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
        console.log(
            'manifest loaded, found ' + data.levels.length + ' quality level',
        );
    });
    hlsObject.on(Hls.Events.BUFFER_EOS, async function (event, data) {
        console.log("BUFFER EOS fired!");
    });
    hlsObject.on(Hls.Events.ERROR, async function (event, data) {
        var errorType = data.type;
        var errorDetails = data.details;
        var errorFatal = data.fatal;
        console.log(`${errorFatal ? 'FATAL' : ''} ERROR: ${errorType}: ${errorDetails}.`);

        if (errorFatal) {
            console.log(`Reinit audio`);

            window.stopListen();
            window.audioInit(url);

            return;
        }

        switch (data.details) {
            case Hls.ErrorDetails.FRAG_LOAD_ERROR:
                console.log(`Fragment load error, current play time ${audioElement.currentTime}. Total duration: ${audioElement.duration}`);
                audioElement.currentTime = audioElement.duration - 2;
                break;
            default:
                break;
        }
    });

    hlsObject.loadSource(url);

    // bind them together
    hlsObject.attachMedia(audioElement);
    audioElement.play();
    //}
};

window.stopListen = function () {
    hlsObject?.destroy();
};

window.getAudioInfo = function () {
    const audioInfo: AudioInfo = { currentTimeSeconds: audioElement?.currentTime ?? 0, name: "testStream" };
    return audioInfo;
};

window.setVolume = function (newVolumeValue: number) {
    audioElement = getAudioElement();
    if (audioElement) {
        audioElement.volume = newVolumeValue;
    }
}

window.setMuted = function (isMuted: boolean) {
    audioElement = getAudioElement();
    if (audioElement) {
        audioElement.muted = isMuted;
    }
}

const getAudioElement: () => HTMLAudioElement = () => document.querySelector("#audioPlayer");

onload = async () => {
    audioElement = getAudioElement();

    const fingerprint = await getCurrentBrowserFingerPrint();
    const cachedFingerprint = localStorage.getItem('jamr.fingerprint');
    const cachedUuid = localStorage.getItem('jamr.uuid');

    if (!cachedFingerprint || fingerprint?.toString() !== cachedFingerprint.toString()) {
        localStorage.setItem('jamr.fingerprint', fingerprint);
    }

    if (!cachedUuid) {
        localStorage.setItem('jamr.uuid', crypto.randomUUID());
    }
}
